/* eslint-disable */
import numeral from 'numeral';

export const formatNumber = (num) => numeral(num).format('0,00');
export const formatNumberDecimals = (num) => numeral(num).format('0,00.0');
export const formatNumberOnlyDecimals = (num) => numeral(num).format('0.0');
export const formatNumberPercent = (num) => numeral(num).format('0.0 %');
export const formatNumberPercentNoDec = (num) => numeral(num).format('0 %');
export const frequencyConverter = (value) => {
  if (value === 1) return 'Year';
  if (value === 12) return 'Month';
  if (value === 52) return 'Week';
};

/**
 * Takes current year, a year in the future, and returns and array of years between start and stop, filled out by step.
 * @param {Date} start -> start year, e.g. 2020
 * @param {Date} stop -> stop year, e.g. 2025
 * @param {number} step -> steps to iterate years by. typically 1 for each added year.
 * @returns array with range of years, for example 2020, 2021, 2022, 2023, 2024, 2025
 */
export const yearRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

/**
 *
 * @param {string} nodeCollectionName -> name of the node collection, e.g. '.nodes'
 * @param {string} type -> type of value in the node. Either .textContent or .value
 * @returns array of the nodes in the node collection
 */
export const getArrayFromNodelist = (nodeCollectionName, type) =>
  Array.from(document.querySelectorAll(nodeCollectionName)).map((item) =>
    type === 'textContent' ? +item.textContent.split(',').join('') : +item.value
  );

/**
 * Sums up all values in an array, per index(column).
 * @param {array} array with nested arrays that contain the values to be summed
 * @returns one array with the sum of the nested arrays, for each index
 */
export const sumEachColumnOfArray = (array) => {
  const sum = array.reduce((acc, curr) => {
    return acc.map((item, index) => item + curr[index]);
  });
  return sum;
};
