/*eslint-disable*/

import axios from 'axios';
import { removeSpinner, renderSpinner, showAlert } from './alerts';

// CREATE FINDATA TEMPLATE
export const createDataTemplate = async (data, clickLocation) => {
  try {
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/finData/',
      data,
    });

    if (res.data.status === 'success') {
      if (clickLocation === 'sign-up') {
        window.setTimeout(() => {
          location.assign('/checkout');
        }, 1500);
      }

      if (clickLocation === 'choose-profile') {
        showAlert('success', 'Template created');
        window.setTimeout(() => {
          location.assign('/overview');
        }, 1500);
      }
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
  }
};

// UPDATE income
export const updateIncome = async (income) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/income/',
      data: {
        income,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Income updated');

      window.setTimeout(() => {
        location.assign('/income');
      }, 1500);
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};

// UPDATE expenses
export const updateExpenses = async (expenses) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/expenses/',
      data: {
        expenses,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Expenses updated');
      window.setTimeout(() => {
        location.assign('/expenses');
      }, 1500);
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};

// UPDATE balance items
export const updateBalanceItems = async (items) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/balances/',
      data: {
        items,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Balance items updated');
      window.setTimeout(() => {
        location.assign('/balances');
      }, 1500);
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};

// UPDATE savings
export const updateSavings = async (savingItems) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'PATCH',
      url: '/api/v1/savings/',
      data: {
        savingItems,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Savings updated');
      window.setTimeout(() => {
        location.assign('/savings');
      }, 1500);
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};
