/* eslint-disable */

//////////////////////////////////////////
// MAKE MOBILE NAVIGATION WORK
const btnNavEl = document.querySelector('.btn-mobile-nav');
const headerEl = document.querySelector('.header');
if (btnNavEl) {
  btnNavEl.addEventListener('click', function () {
    headerEl.classList.toggle('nav-open');
  });
}

//////////////////////////////////////////
// SMOOTH SCROLLING
const allLinks = document.querySelectorAll('a:link');
const hero = document.querySelector('.section-hero');

if (hero) {
  allLinks.forEach((link) => {
    link.addEventListener('click', (e) => {
      const href = link.getAttribute('href');
      if (!href.startsWith('#')) return;
      e.preventDefault();

      // Scroll to top
      if (href === '#') window.scrollTo({ top: 0, behavior: 'smooth' });

      // Scroll to section on page
      if (href !== '#' && href.startsWith('#')) {
        const selectionEl = document.querySelector(href);
        selectionEl.scrollIntoView({ behavior: 'smooth' });
      }

      //IF we later have mobile navigation - implement code to also close the navigation on top of page.
      if (link.classList.contains('nav__list-link'))
        headerEl.classList.remove('nav-open');
    });
  });
}

//////////////////////////////////////////
// STICKY NAV
if (hero) {
  const header = document.querySelector('.header--home');
  const navHome = document.querySelector('.nav__container');
  const navHeight = navHome.getBoundingClientRect().height;

  const stickyNav = function (entries) {
    const [entry] = entries;

    if (!entry.isIntersecting) navHome.classList.add('sticky');
    else navHome.classList.remove('sticky');
  };

  const heroObserver = new IntersectionObserver(stickyNav, {
    root: null,
    threshold: 0,
    rootMargin: `-${navHeight}px`,
  });
  heroObserver.observe(header);
}

//////////////////////////////////////////
// REVEAL SECTION ON SCROLL
// const allSections = document.querySelectorAll('.section');

// const revealSection = function (entries, observer) {
//   const [entry] = entries;
//   if (!entry.isIntersecting) return;

//   entry.target.classList.remove('section--hidden');
//   observer.unobserve(entry.target);
// };
// const sectionObserver = new IntersectionObserver(revealSection, {
//   root: null,
//   threshold: 0.15,
// });
// allSections.forEach(function (section) {
//   sectionObserver.observe(section);
//   section.classList.add('section--hidden');
// });

export const addHomepageCalcRow = function (e, type) {
  const clicked = e.target.closest('.homepage-form').children[0];

  const html = `
    <div class="form__row ${type === 'asset' ? 'asset-row' : 'liability-row'} ">
      <input type="text" placeholder="${
        type === 'asset' ? 'Asset' : 'Liability'
      } name" class="form__input form__input--homepage">
      <input type="number" placeholder="Value of ${
        type === 'asset' ? 'asset' : 'liability'
      }" class="form__input form__input--homepage ${
    type === 'asset' ? 'asset' : 'liability'
  }-value">

    </div>
    `;

  clicked.insertAdjacentHTML('beforeend', html);
  clicked.lastElementChild.firstElementChild.focus();
};
