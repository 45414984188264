/* eslint-disable */

export const hideAlert = () => {
  const el = document.querySelector('.alert');
  if (el) el.parentElement.removeChild(el);
};

// type is 'success' or 'error'
export const showAlert = (type, msg) => {
  hideAlert();

  const markup = `<div class="alert alert--${type}">${msg}</div>`;
  document.querySelector('body').insertAdjacentHTML('afterbegin', markup);
  window.setTimeout(hideAlert, 5000);
};

export const renderSpinner = () => {
  const markup = `
  <div class="spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  `;

  document.body.insertAdjacentHTML('afterbegin', markup);
};

export const removeSpinner = () => {
  const spinner = document.querySelector('.spinner');
  if (spinner) {
    spinner.remove();
  }
};
