/*eslint-disable*/
/**
 *
 * @param {boolean} newUser - true = user just signed up and profile type not yet chosen.
 * @param {number} profileType - numbers 1 to 4 correspond to profile types 'Strong % single', 'DINK', 'A crew' and 'Retiree'
 * @returns Dataset to populate finData template with based on
 */
export const populatedData = (newUser, profileType) => {
  // 1) Created empty data for finData template to be used.
  const data = {
    fixedExpenses: [],
    variableExpenses: [],
    occasionalExpenses: [],
    fixedIncome: [],
    variableIncome: [],
    occasionalIncome: [],
    assets: [],
    liabilities: [],
  };

  // 2) If the user chooses a template, add all common finData items.
  if (!newUser) {
    data.fixedExpenses.push(
      { name: 'Phone bill' },
      { name: 'Electricity / gas' },
      { name: 'Insurance' },
      { name: 'Streaming' },
      { name: 'Software' },
      { name: 'Gym membership' }
    );
    data.variableExpenses.push(
      { name: 'Groceries' },
      { name: 'Restaurants' },
      { name: 'Transportation' },
      { name: 'Personal care' },
      { name: 'Hobbies' },
      { name: 'Entertainment' },
      { name: 'Medicine' }
    );
    data.occasionalExpenses.push(
      {
        name: 'Vacation',
        frequency: 1,
      },
      {
        name: 'Health check',
        frequency: 1,
      },
      {
        name: 'Dentist',
        frequency: 1,
      },
      {
        name: 'Eye care',
        frequency: 1,
      }
    );

    data.assets.push(
      { name: 'Cash' },
      { name: 'Stocks' },
      { name: 'Retirement fund' }
    );
    data.liabilities.push(
      { name: 'Credit card debt' },
      { name: 'Student loan' }
    );
  }

  // 3) Depending on chosen profile, add associated finData items.
  // profileType 1 = Single profile
  if (profileType === 1) {
    data.fixedExpenses.push(
      { name: 'Rent' },
      { name: 'Student loan payments' }
    );
    data.variableExpenses.push({ name: 'Parties' }, { name: 'Dates' });
    data.fixedIncome.push({ name: 'Salary' });
    data.variableIncome.push({ name: 'Bonus' });
  }

  // profileType 2 = DINK
  if (profileType === 2) {
    data.fixedExpenses.push(
      { name: 'Mortgage payments' },
      { name: 'Student loan payments' },
      { name: 'Charity' }
    );
    data.variableExpenses.push(
      { name: 'Parties' },
      { name: 'Date nights' },
      { name: 'Car payments' },
      { name: 'Fuel' }
    );
    data.occasionalExpenses.push({
      name: 'Vehicle maintenance',
      frequency: 1,
    });
    data.fixedIncome.push({ name: 'Salary' });
    data.variableIncome.push(
      { name: 'Bonus' },
      { name: 'Commission' },
      { name: 'Rental income' }
    );
    data.assets.push({ name: 'House' });
    data.liabilities.push({ name: 'Mortgage' }, { name: 'Car loan' });
  }

  // profileType 3 = Family / crew
  if (profileType === 3) {
    data.fixedExpenses.push(
      { name: 'Mortgage payments' },
      { name: 'Student loan payments' },
      { name: 'Charity' }
    );
    data.variableExpenses.push(
      { name: "Children's clothes" },
      { name: 'Child care' },
      { name: 'Outings' },
      { name: "Children's birthday parties" },
      { name: 'Car payments' },
      { name: 'Fuel' }
    );

    data.occasionalExpenses.push({
      name: 'Vehicle maintenance',
      frequency: 1,
    });
    data.fixedIncome.push({ name: 'Salary' });
    data.variableIncome.push(
      { name: 'Bonus' },
      { name: 'Commission' },
      { name: 'Rental income' }
    );
    data.assets.push({ name: 'House' }, { name: 'College fund' });
    data.liabilities.push({ name: 'Mortgage' }, { name: 'Car loan' });
  }

  // profileType 4 = retiree
  if (profileType === 4) {
    data.fixedExpenses.push({ name: 'Mortgage payments' }, { name: 'Charity' });
    data.variableExpenses.push({ name: 'Fuel' });

    data.occasionalExpenses.push({
      name: 'Vehicle maintenance',
      frequency: 1,
    });

    data.fixedIncome.push({ name: 'Pension payments' });
    data.variableIncome.push({ name: 'Dividends' });
    data.assets.push({ name: 'House' }, { name: 'Bonds' });
    data.liabilities = [{ name: 'Mortgage' }, { name: 'Credit card debt' }];
  }
  return data;
};

// TODO - to comment out / in when encryption on field DB level is initiated for production server

// export const populatedLevels = () => {
//   const data = {
//     safety: [
//       { name: 'rent' },
//       { name: 'groceries' },
//       { name: 'electricity and water' },
//       { name: 'transportation' },
//       { name: 'basic insurance' },
//     ],
//     vitality: [
//       { name: 'clothes and accesories' },
//       { name: 'restaurants' },
//       { name: 'entertainment' },
//       { name: 'small luxury' },
//     ],
//     independence: [
//       { name: 'insurance' },
//       { name: 'phone plan' },
//       { name: 'internet' },
//       { name: 'subscriptions' },
//       { name: 'shopping' },
//       { name: 'health and fitness' },
//       { name: 'transportation' },
//       { name: 'home furniture' },
//       { name: 'education' },
//       { name: 'travel and holiday' },
//     ],
//     freedom: [
//       { name: 'additional rent' },
//       { name: 'additional travel and holiday' },
//       { name: 'additional shopping' },
//       { name: 'additional education' },
//       { name: 'additional transportation' },
//       { name: 'additional entertainment' },
//       { name: 'additional food and drinks' },
//       { name: 'additional health and fitness' },
//       { name: 'family and pets' },
//     ],
//     absoluteFreedom: [
//       { name: 'additional rent' },
//       { name: 'additional travel and holiday' },
//       { name: 'additional shopping' },
//       { name: 'additional transportation' },
//       { name: 'additional insurance' },
//       { name: 'additional entertainment' },
//       { name: 'additional food and drinks' },
//       { name: 'additional health and fitness' },
//     ],
//   };

//   console.log(data);
// };

// TODO - REMOVE BACKUP IF TEMPLATE FOR FINDATA IS WORKING
// const data = {
//   fixedExpenses: [
//     {
//       name: 'Phone bill',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Electricity / gas',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Insurance',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Streaming',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Software',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Gym membership',
//       value: 0,
//       frequency: 12,
//     },
//   ],
//   variableExpenses: [
//     {
//       name: 'Groceries',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Restaurants',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Transportation',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Personal care',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Hobbies',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Entertainment',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Medicine',
//       value: 0,
//       frequency: 12,
//     },
//   ],
//   occasionalExpenses: [
//     {
//       name: 'Vacation',
//       value: 0,
//       frequency: 1,
//     },
//     {
//       name: 'Health check',
//       value: 0,
//       frequency: 1,
//     },
//     {
//       name: 'Dentist',
//       value: 0,
//       frequency: 1,
//     },
//     {
//       name: 'Eye care',
//       value: 0,
//       frequency: 1,
//     },
//   ],

//   fixedIncome: [
//     {
//       name: 'Salary',
//       value: 0,
//       frequency: 12,
//     },
//   ],
//   variableIncome: [
//     {
//       name: 'Bonus',
//       value: 0,
//       frequency: 12,
//     },
//     {
//       name: 'Commission',
//       value: 0,
//       frequency: 12,
//     },
//   ],
//   occasionalIncome: [],

//   assets: [
//     {
//       name: 'Cash',
//       value: 0,
//       rate: 0,
//     },
//     {
//       name: 'Stocks',
//       value: 0,
//       rate: 0,
//     },
//     {
//       name: 'Retirement fund',
//       value: 0,
//       rate: 0,
//     },
//   ],
//   liabilities: [
//     {
//       name: 'Credit card debt',
//       value: 0,
//       rate: 0,
//     },
//     {
//       name: 'Student loan',
//       value: 0,
//       rate: 0,
//     },
//   ],
// };
