/* eslint-disable */

// const ApexCharts = require('apexcharts');
import ApexCharts from 'apexcharts';
import numeral from 'numeral';

const colorPrimary = '#087f5b';
const colorPrimaryLight = '#7ed56f';
const colorPrimaryLighter = '#9cccbd';
const colorPrimaryDark = '#066649';
const colorPrimaryDarkest = '#02261b';
const colorAccent = '#11447e';
const defaultFontSize = '16px';
const smallFontSize = '12px';

// DEMOS
// https://apexcharts.com/javascript-chart-demos/dashboards/modern/

const numSeparatorComma = (val) => numeral(+val).format('0,00');
const numShort = (val) => numeral(+val).format('0a');
const numShortMil = (val) => numeral(+val).format('0.0a');

export const donutChart = function (dataSeries, labels, chartId) {
  const Options = {
    chart: {
      type: 'donut',
    },
    legend: {
      position: 'bottom',
    },
    height: '100%',
    series: dataSeries,
    labels: labels,
    dataLabels: {
      enabled: true,
      style: {
        fontSize: smallFontSize,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
            },
            value: {
              show: true,
              formatter: numSeparatorComma,
            },
          },
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: [colorPrimary, colorPrimaryDarkest, colorPrimaryLight],
  };

  return new ApexCharts(document.querySelector(chartId), Options);
};

// Balance sheet chart test
// export const columnChart = function (dataSeries, labels, chartId, seriesName) {
export const stackedBarChart = function (
  assets,
  liabilities,
  netWorth,
  chartId
) {
  const Options = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false,
      },
    },

    series: [
      {
        name: 'Assets',
        data: [assets, 0],
        color: colorPrimaryLighter,
      },
      {
        name: 'Liabilities',
        data: [0, liabilities],
        color: colorPrimaryLighter,
      },
      {
        name: 'Net worth',
        data: [0, netWorth],
        color: colorPrimary,
      },
    ],
    xaxis: {
      type: 'category',
      categories: ['Assets', 'Net worth and liabilities'],
      labels: {
        rotate: 0,
        trim: true,
      },
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val >= 1000000) return numeral(+val).format('0.0a');
          if (val < 1000000) return numeral(+val).format('0a');
        },
        // formatter: numSeparatorComma,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: numSeparatorComma,
    },
    legend: {
      show: false,
    },
    fill: {
      colors: [colorPrimary, colorPrimaryDark, colorAccent],
    },
    // tooltip: {
    //   enabled: false,
    // },
  };
  return new ApexCharts(document.querySelector(chartId), Options);
};

export const barChart = function (dataSeries, labels, chartId, seriesName) {
  const Options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
        // TODO ALSO MAKE SURE TO DISABLE ZOOM
      },
    },
    zoom: {
      enabled: false,
    },
    series: [
      {
        name: seriesName,
        data: dataSeries,
      },
    ],
    labels: labels,

    dataLabels: {
      enabled: true,
      formatter: numSeparatorComma,
    },
    fill: {
      colors: [colorPrimary, colorPrimaryDarkest, colorPrimaryLight],
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          if (val >= 1000000) return numeral(+val).format('0.0a');
          if (val < 1000000) return numeral(+val).format('0a');
        },
      },
    },
    xaxis: {
      labels: {
        rotate: 0,
        trim: true,
      },
    },
    // tooltip: {
    //   enabled: false,
    // },
  };

  return new ApexCharts(document.querySelector(chartId), Options);
};

export const lineChart = function (dataSeries, yearsTimeSeries, chartId) {
  const Options = {
    chart: {
      type: 'line',
      // height: '350px',
      toolbar: {
        show: false,
      },
    },
    zoom: {
      enabled: false,
    },

    series: dataSeries,
    colors: [colorPrimary, colorPrimaryDarkest],

    xaxis: {
      categories: yearsTimeSeries,
      labels: {
        rotate: -45,
        hideOverlappingLabels: true,
      },
      tickAmount: 6,
      tickPlacement: 'on',
    },

    yaxis: {
      labels: {
        formatter: function (val) {
          if (val >= 1000000) return numeral(+val).format('0.0a');
          if (val < 1000000) return numeral(+val).format('0a');
        },
      },
    },
  };

  return new ApexCharts(document.querySelector(chartId), Options);
};
