/*eslint-disable*/

import axios from 'axios';
import { removeSpinner, renderSpinner, showAlert } from './alerts';
import { populatedData } from './populatedDataSets';
import { createDataTemplate } from './updateFinData';

export const createUserData = async () => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'POST',
      url: '/api/v1/finData/',
    });

    if (res.data.status === 'success') {
      createDataTemplate(populatedData(true, 0), 'sign-up');
    }
  } catch (err) {
    showAlert('error', err.response.data.message);
    removeSpinner();
  }
};

export const signup = async (name, email, password, passwordConfirm) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/signup',
      data: {
        name,
        email,
        password,
        passwordConfirm,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Signed up successfully!');
      createUserData();
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};

export const verifyAccount = async (verificationToken) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'POST',
      url: `/api/v1/users/verifyAccount/${verificationToken}`,
      data: {
        verificationToken,
      },
    });

    if (res.data.status === 'success') {
      // showAlert('success', 'User account validated');
      const section = document.getElementById('section-verify');
      const html = `
        <div class="homeform-box">
          <h3 class="heading-tertiary">Account verified!</h3>
          <p class="u-center-text">Thank you for verifying your account and helping to keep our platform safe. We are delighted to have you here and look forward to helping you on your financial journey!</p> 
          <p class="u-center-text">You can now close this tab.</p>
        </div> 
      `;
      section.innerHTML = '';
      section.insertAdjacentHTML('afterbegin', html);

      removeSpinner();
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};
