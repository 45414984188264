/*eslint-disable*/

import axios from 'axios';
import { removeSpinner, renderSpinner, showAlert } from './alerts';

export const login = async (email, password) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/login',
      data: {
        email,
        password,
      },
    });

    if (res.data.status === 'success') {
      location.assign('/overview');
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};

export const forgotPassword = async (email) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'POST',
      url: '/api/v1/users/forgotPassword',
      data: { email },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'A password reset link has been sent to your email');
      document.querySelector('.home-form--btn').textContent = 'Reset password';
      window.setTimeout(() => {
        location.assign('/login');
      }, 5000);
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};

export const resetPassword = async (password, passwordConfirm, token) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'PATCH',
      url: `/api/v1/users/resetPassword/${token}`,
      data: {
        password,
        passwordConfirm,
      },
    });

    if (res.data.status === 'success') {
      showAlert('success', 'Password successfully updated');
      window.setTimeout(() => {
        location.assign('/login');
      }, 5000);
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};

export const logout = async () => {
  try {
    const res = await axios({
      method: 'GET',
      url: '/api/v1/users/logout',
    });

    if (res.data.status === 'success') location.assign('/login');
  } catch (err) {
    showAlert('error', 'Error logging out. Please try again.', err);
  }
};
