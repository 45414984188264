/* eslint-disable */

import { lineChart, donutChart, barChart, stackedBarChart } from './apexCharts';
import { showAlert } from './alerts';
import {
  formatNumber,
  getArrayFromNodelist,
  sumEachColumnOfArray,
  yearRange,
} from './helper';

// CHARTS
// All income on the income page.
export const incomeChart = function () {
  const income = [
    document.querySelector('.fix-inc-total'),
    document.querySelector('.var-inc-total'),
    document.querySelector('.occ-inc-total'),
  ].map((inc) => +inc.textContent.split(',').join(''));

  const dataLabels = ['Fixed', 'Variable', 'Occasional'];

  const incomeChart = donutChart(income, dataLabels, '#inc-chart');
  incomeChart.render();
};

// All expenses on the expenses page.
export const expenseChart = function () {
  const expenses = [
    document.querySelector('.fix-exp-total'),
    document.querySelector('.var-exp-total'),
    document.querySelector('.occ-exp-total'),
  ].map((el) => +el.textContent.split(',').join(''));

  const dataLabels = ['Fixed', 'Variable', 'Occasional'];

  const expenseChart = donutChart(expenses, dataLabels, '#exp-chart');
  expenseChart.render();
};

// Income and expenses compared, on overview page
export const incomeExpenseChart = function () {
  const values = [
    document.querySelector('.tot-monthly-inc'),
    document.querySelector('.tot-monthly-exp'),
    // document.querySelector('.monthly-inc-exp-diff'),
  ].map((val) => +val.textContent.split(',').join(''));

  const dataLabels = ['Income', 'Expenses'];

  const incExpChart = barChart(values, dataLabels, '#inc-exp-chart', 'Value');

  incExpChart.render();
};

// Net worth chart
export const netWorthChart = function () {
  const balances = [
    document.querySelector('.blc-tot-ass'),
    document.querySelector('.blc-tot-lia'),
    document.querySelector('.blc-net-worth'),
  ].map((bal) => +bal.textContent.split(',').join(''));

  const chart = stackedBarChart(
    balances[0],
    balances[1],
    balances[2],
    '#net-worth-chart'
  );
  chart.render();
};

export const netWorthChartHomepage = function () {
  const assetValue = [...document.querySelectorAll('.asset-value')]
    .map((el) => +el.value)
    .reduce((a, b) => a + b, 0);

  const liabValue = [...document.querySelectorAll('.liability-value')]
    .map((el) => +el.value)
    .reduce((a, b) => a + b, 0);

  if (assetValue === 0 && liabValue === 0) {
    document.querySelector('.form__input--homepage').focus();

    return showAlert(
      'error',
      'Please enter at least one value in the asset & liability rows'
    );
  }

  if (!document.getElementById('net-worth-chart-homepage')) {
    document.querySelector('.section-try').firstElementChild.insertAdjacentHTML(
      'beforeend',
      `<div class="try__container try__container--joint" id="try__container--joint">
        <div class="content__box try__box--transparent">
          <h3 class="heading-tertiary u-center-text">Summary</h3>  
          <p>You have a <span class="span__bold"> net worth of ${formatNumber(
            assetValue - liabValue
          )}</span>. This is calculated by taking all your assets (${formatNumber(
        assetValue
      )}) and subtracting your liabilities (${formatNumber(liabValue)}).</p>
          <div class="content__type--bottom">
            <ul class="try__list">
              <li class="try__list--item">
                <p>With the balance sheet tool you can:</p>
              </li>
              <li class="try__list--item">
                <svg class="try__list--icon">
                  <use xlink:href="/img/icons.svg#icon-chevron-right"></use>
                </svg>
                <p>Create a comprehensive overview of your finances</p>
              </li>
              <li class="try__list--item">
                <svg class="try__list--icon">
                  <use xlink:href="/img/icons.svg#icon-chevron-right"></use>
                </svg>
                <p>Calculate potential growth of your assets</p>
              </li>
              <li class="try__list--item">
                <svg class="try__list--icon">
                  <use xlink:href="/img/icons.svg#icon-chevron-right"></use>
                </svg>
                <p>See how inflation affects your asset growth</p>
              </li>
              <li class="try__list--item try__list--sign-up-item">
                <a class="btn btn--full try--sign-up" href="/signup">Sign up</a>
                <p class="try__underline"> to set up your finances</p>
            </ul>
          </div>
        </div>
        <div class="content__box try__box--transparent try__box--graph">
          <div id="net-worth-chart-homepage"></div>
        </div>
      </div>`
    );
  }
  const chart = stackedBarChart(
    assetValue,
    liabValue,
    assetValue - liabValue,
    '#net-worth-chart-homepage'
  );
  chart.render();
  document
    .getElementById('net-worth-chart-homepage')
    .scrollIntoView({ behavior: 'smooth', block: 'center' });
};

// Charts (plural) for each individual asset on the balance sheet page.
export const individualFuturesCharts = function (noOfGraphs) {
  // Put in if statement since the function seems to run otherwise when on another page than balance sheet.
  if (noOfGraphs !== 0) {
    const yearsTimeSeries = getArrayFromNodelist('.item-year', 'textContent');

    const timeline = document.getElementById('graph-years-bal').value;

    for (let i = 0; i < noOfGraphs; i++) {
      const assetName = document.querySelector(
        `.chart-asset-name-${i}`
      ).textContent;
      const values = [];
      const inflatedValues = [];

      const allValues = getArrayFromNodelist(`.asset-${i}`, 'textContent');

      const allInflatedValues = getArrayFromNodelist(
        `.asset-inflation-${i}`,
        'textContent'
      );

      for (let j = 0; j < timeline; j++) {
        values.push(allValues[j]);
        inflatedValues.push(allInflatedValues[j]);
      }

      const dataSeries = [
        {
          name: assetName,
          data: values,
        },
        {
          name: `Inflation adjusted ${assetName}`,
          data: inflatedValues,
        },
      ];

      const futureAssetChart = lineChart(
        dataSeries,
        yearsTimeSeries,
        `#futures-chart-${i}`
      );

      futureAssetChart.render();
    }
  }
};

// Chart for all future assets combined
export const allFutureAssetChart = function () {
  const yearsTimeSeries = getArrayFromNodelist('.item-year', 'textContent');

  const timeline = document.getElementById('graph-years-bal').value;

  if (document.querySelector('#futures-chart-all')) {
    const values = [];
    const inflatedValues = [];

    const allValues = getArrayFromNodelist('.asset-all', 'textContent');
    const allInflatedValues = getArrayFromNodelist(
      '.asset-all-inflated',
      'textContent'
    );

    for (let j = 0; j < timeline; j++) {
      values.push(allValues[j]);
      inflatedValues.push(allInflatedValues[j]);
    }

    const dataSeries = [
      {
        name: 'All assets',
        data: values,
      },
      {
        name: 'All assets adjusted for inflation',
        data: inflatedValues,
      },
    ];

    const allFuturesCharts = lineChart(
      dataSeries,
      yearsTimeSeries,
      `#futures-chart-all`
    );

    allFuturesCharts.render();
  }
};

// Savings chart including growth for overview page
export const savingsGrowthChart = function () {
  const values = [
    document.querySelector('.sav-gro-today'),
    document.querySelector('.sav-gro-20yr-incl-sav'),
  ].map((val) => +val.textContent.split(',').join(''));

  const dataLabels = ['Today', '20 years incl. savings'];

  const savGroChart = barChart(
    values,
    dataLabels,
    '#savings-growth-chart',
    'Value'
  );

  savGroChart.render();
};

// Future savings chart fot ALL savings
export const allFutureSavingsChart = function () {
  const yearsTimeSeries = Array.from(
    document.querySelectorAll('.item-year')
  ).map((item) => +item.textContent);

  const timeline = document.getElementById('graph-years-sav').value;

  if (document.querySelector('#all-futures-sav-chart')) {
    const exclSavings = [];
    const inclSavings = [];

    const allExclSavings = getArrayFromNodelist(
      '.asset-excl-sav-total',
      'textContent'
    );

    const allInclSavings = getArrayFromNodelist(
      '.asset-incl-sav-total',
      'textContent'
    );

    for (let j = 0; j < timeline; j++) {
      exclSavings.push(allExclSavings[j]);
      inclSavings.push(allInclSavings[j]);
    }

    const dataSeries = [
      {
        name: 'Including savings',
        data: inclSavings,
      },
      {
        name: 'Excluding savings',
        data: exclSavings,
      },
    ];

    const chart = lineChart(
      dataSeries,
      yearsTimeSeries,
      `#all-futures-sav-chart`
    );

    chart.render();
  }
};

// Future savings chart for EACH SAVING on savings page
export const futureSavingsCharts = function (noOfGraphs) {
  if (noOfGraphs !== 0) {
    const yearsTimeSeries = Array.from(
      document.querySelectorAll('.item-year')
    ).map((item) => +item.textContent);

    const timeline = document.getElementById('graph-years-sav').value;

    for (let i = 0; i < noOfGraphs; i++) {
      const assetName = document.querySelector(
        `.chart-asset-name-${i}`
      ).textContent;
      const valuesInclSav = [];
      const valuesExclSav = [];

      const allValuesInclSav = Array.from(
        document.querySelectorAll(`.asset-incl-sav-${i}`)
      ).map((item) => +item.textContent.split(',').join(''));

      const allValuesExclSav = Array.from(
        document.querySelectorAll(`.asset-excl-sav-${i}`)
      ).map((item) => +item.textContent.split(',').join(''));

      for (let j = 0; j < timeline; j++) {
        valuesInclSav.push(allValuesInclSav[j]);
        valuesExclSav.push(allValuesExclSav[j]);
      }

      const dataSeries = [
        {
          name: 'Including savings',
          data: valuesInclSav,
        },

        {
          name: 'Excluding savings',
          data: valuesExclSav,
        },
      ];

      const futuresSavingChart = lineChart(
        dataSeries,
        yearsTimeSeries,
        `#futures-sav-chart-${i}`
      );

      futuresSavingChart.render();
    }
  }
};

export const allProjectionChart = function () {
  const numberOfYears = +document.querySelector('#projection-years').value;
  const numberOfAssets = getArrayFromNodelist(
    '.projection-asset',
    'textContent'
  ).length;
  const currentYear = new Date().getFullYear();
  const years = yearRange(currentYear, currentYear + numberOfYears, +1);
  const inflation = +document.querySelector('#projection-inflation').value;
  const assets = [];
  const allFutureAssets = [];

  // Each asset row contains 1) value, 2) monthly savings amount, 3) annual growth rate %, and 4) fee %
  for (let i = 0; i < numberOfAssets; i++) {
    assets.push(getArrayFromNodelist(`.asset-row-${i}`, 'value'));
  }

  assets.forEach((asset) => {
    // A) For each asset, take the asset value and apply the monthly savings, (+) AGR, (-) fees and (-) inflation to get a value for each year.
    const futureValues = [asset[0]];
    for (let j = 0; j < numberOfYears; j++) {
      futureValues.push(
        (futureValues[j] + asset[1] * 12) *
          (1 + (asset[2] - asset[3] - inflation) / 100)
      );
    }
    allFutureAssets.push(futureValues);
  });

  // B) Combine those values at the end, so that we have one value per year for all assets.
  const allAssetValues = sumEachColumnOfArray(allFutureAssets);

  const dataSeries = [
    {
      name: 'Future assets',
      data: allAssetValues,
    },
  ];

  const allProjectionsChart = lineChart(
    dataSeries,
    years,
    `#all-projections-chart`
  );

  allProjectionsChart.render();
};
