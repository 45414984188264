/* eslint-disable*/
import { showAlert } from './alerts';

////////////////////////////////////
//SELECTORS
// Modals
const modal = document.querySelector('.modal');
const modals = document.querySelectorAll('.modal');
const overlay = document.querySelector('.overlay');

//////////////////////////////////////////
//MODAL WINDOW
// Internal functions
const grabIncExpRows = function (rowType, array, type) {
  const items = [...document.querySelectorAll(rowType)].map(
    (row) => row.children
  );

  for (let i = 0; i < items.length; i++) {
    if (items[i][0].value !== '') {
      array.push({
        name: items[i][0].value,
        value: Number(items[i][1].value),
        frequency: Number(items[i][2].value),
      });
    }
  }
};

const grabBalRows = function (rowType, array) {
  const items = [...document.querySelectorAll(rowType)].map(
    (row) => row.children
  );

  for (let i = 0; i < items.length; i++) {
    if (items[i][0].value !== '' && rowType === '.asset-row') {
      array.push({
        name: items[i][0].value,
        value: Number(items[i][1].value),
        rate: Number(items[i][2].value / 100),
        savingsAmount: Number(items[i][4].textContent),
      });
    }

    if (items[i][0].value !== '' && rowType === '.liability-row') {
      array.push({
        name: items[i][0].value,
        value: Number(items[i][1].value),
        rate: Number(items[i][2].value / 100),
      });
    }
  }
};

const grabSavings = function (inputType, array) {
  const items = [...document.querySelectorAll(inputType)].map(
    (row) => row.children
  );

  for (let i = 0; i < items.length; i++) {
    array.push({
      name: items[i][0].textContent,
      value: Number(items[i][1].attributes[1].value),
      rate: Number(items[i][2].attributes[1].value),
      savingsAmount: Number(items[i][3].value),
    });
  }
};

// External functions
export const openModal = function (e) {
  const clicked = e.target.closest('.btn--open-modal');

  // Guard clause
  if (!clicked) return;

  document.querySelector('.modal').classList.remove('hidden');

  overlay.classList.remove('hidden');
};

export const openModals = function (e) {
  const clicked = e.target.closest('.btn--open-modal');

  // Guard clause
  if (!clicked) return;

  document
    .querySelector(`.modal__start--${clicked.dataset.modal}`)
    .classList.remove('hidden');

  overlay.classList.remove('hidden');
};

export const closeModal = function (e) {
  e.preventDefault();
  modal.classList.add('hidden');
  overlay.classList.add('hidden');
};

export const closeModals = function (e) {
  e.preventDefault();
  [...modals].forEach((m) => m.classList.add('hidden'));
  overlay.classList.add('hidden');
};

export const addIncExpRow = function (e) {
  const clicked = e.target.closest('.form__container').children[1];

  // Income elements
  const fixIncRow = document.querySelector('.modal__form--fixed-income');
  const varIncRow = document.querySelector('.modal__form--variable-income');
  const occIncRow = document.querySelector('.modal__form--occasional-income');

  // Expense elements
  const fixExpForm = document.querySelector('.modal__form--fixed-expenses');
  const varExpForm = document.querySelector('.modal__form--variable-expenses');
  const occExpForm = document.querySelector(
    '.modal__form--occasional-expenses'
  );

  // Code to generate
  html = `
  <div class='modal__form--row ${
    clicked === fixExpForm ? 'fixed-expense-row' : ''
  }${clicked === varExpForm ? 'variable-expense-row' : ''}${
    clicked === occExpForm ? 'occasional-expense-row' : ''
  }${clicked === fixIncRow ? 'fixed-income-row' : ''}${
    clicked === varIncRow ? 'variable-income-row' : ''
  }${clicked === occIncRow ? 'occasional-income-row' : ''}'>
  <input class="form__input form__input--name" type="text" placeholder="Insert name" ></input>
  <input class="form__input form__input--amount u-right-text" type="number" placeholder="Insert value" ></input>
  <select class="form__input form__input--frequency">
  <option value="12" selected disabled hidden>Monthly</option>
  <option value="52">Weekly</option>
  <option value="12">Monthly</option>
  <option value="1">Yearly</option>
  </select>
  <button type="button" class="btn btn__small--remove">
    <svg class="form-row-delete-icon" name="delete">
      <use xlink:href="/img/icons.svg#icon-delete"></use>
    </svg>
  </button>
  </div>
  `;

  // Insert new row
  clicked.insertAdjacentHTML('beforeend', html);

  // Focus on newly inserted row
  [...e.target.closest('.form__container').children[1]].slice(-4)[0].focus();

  // Reselect all delete buttons so that they are in the object scope
  btnsRemoveRows = document.querySelectorAll('.btn__small--remove');
  btnsRemoveRows.forEach((btn) =>
    btn.addEventListener('click', (e) => {
      removeRows(e);
    })
  );
};

export const addBalanceRow = function (e) {
  const clicked = e.target.closest('.form__container').children[1];
  const assetForm = document.querySelector('.modal__form--assets');

  html = `
    <div class='modal__form--row ${
      clicked === assetForm ? 'asset-row' : 'liability-row'
    }'>
      <input class='form__input form__input--name asset-name' type='text' placeholder='Insert name' ></input>
      <input class='form__input form__input--amount asset-amount u-right-text' type='number' placeholder='Insert value' ></input>
      <input class='form__input form__input--rate asset-rate u-right-text' type='number' placeholder='Insert rate' ></input>
      <button type="button" class="btn btn__small--remove">
        <svg class="form-row-delete-icon" name="delete">
          <use xlink:href="/img/icons.svg#icon-delete"></use>
        </svg>
      </button>
      <label class='hide-item'>0</label>
    </div>
    `;

  clicked.insertAdjacentHTML('beforeend', html);
  [...e.target.closest('.form__container').children[1]].slice(-4)[0].focus();

  btnsRemoveRows = document.querySelectorAll('.btn__small--remove');
  btnsRemoveRows.forEach((btn) =>
    btn.addEventListener('click', (e) => {
      removeRows(e);
    })
  );
};

export const getIncRows = function (e) {
  const fixedIncome = [];
  const variableIncome = [];
  const occasionalIncome = [];

  grabIncExpRows('.fixed-income-row', fixedIncome);
  grabIncExpRows('.variable-income-row', variableIncome);
  grabIncExpRows('.occasional-income-row', occasionalIncome);

  const incomeItems = {
    fixedIncome,
    variableIncome,
    occasionalIncome,
  };

  return incomeItems;
};

export const getExpRows = function (e) {
  const fixedExpenses = [];
  const variableExpenses = [];
  const occasionalExpenses = [];

  grabIncExpRows('.fixed-expense-row', fixedExpenses);
  grabIncExpRows('.variable-expense-row', variableExpenses);
  grabIncExpRows('.occasional-expense-row', occasionalExpenses);

  const expenseItems = {
    fixedExpenses,
    variableExpenses,
    occasionalExpenses,
  };

  return expenseItems;
};

export const getBalanceRows = function (e) {
  const assets = [];
  const liabilities = [];
  const inflation = +document.querySelector('.inflation').value / 100;

  grabBalRows('.asset-row', assets);
  grabBalRows('.liability-row', liabilities);

  const items = {
    assets: assets,
    liabilities: liabilities,
    inflation: inflation,
  };

  return items;
};

export const getSavingsRows = function () {
  const assets = [];

  // Get all assets from the DOM, to be able to update including savings amount.
  grabSavings('.savings-row', assets);

  // Items to be updated and sent to the controller.
  const savings = {
    assets,
  };

  return savings;
};

// export const checkSavingsPercent = function () {
//   const items = [...document.querySelectorAll('.savings-row')]
//     .map((row) => row.children)
//     .map((el) => +el[3].value)
//     .reduce((a, b) => a + b);

//   if (items === 100 || items < 100) {
//     return true;
//   }

//   if (items > 100) {
//     showAlert('error', 'The total % of savings cannot be more than 100%');
//     return false;
//   }
// };

export const removeRows = function (e) {
  e.preventDefault();
  e.target.closest('.modal__form--row').remove();
};
