/*eslint-disable*/

import axios from 'axios';
import { removeSpinner, renderSpinner, showAlert } from './alerts';

export const sendMessage = async (name, email, subject, message) => {
  try {
    renderSpinner();
    const res = await axios({
      method: 'POST',
      url: '/api/v1/admin/contactForm',
      data: {
        name,
        email,
        subject,
        message,
      },
    });

    if (res.data.status === 'success') {
      removeSpinner();
      const pageContainer = document.getElementById('contact-form');
      pageContainer.textContent = '';
      const html = `
      <h3>Thank you for your message!</h3>
      <p>We will get back to you as soon as possible.</p>
      <a href="/" class="btn btn--full">Back to the home page</a>
      `;
      pageContainer.insertAdjacentHTML('beforeend', html);
    }
  } catch (err) {
    removeSpinner();
    showAlert('error', err.response.data.message);
  }
};
