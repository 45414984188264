/* eslint-disable */

import 'regenerator-runtime/runtime';
// import '@babel/polyfill';
import { login, forgotPassword, logout, resetPassword } from './login';
import { signup, createUserData, verifyAccount } from './signup';
import {
  addBalanceRow,
  addIncExpRow,
  checkSavingsPercent,
  closeModal,
  closeModals,
  getBalanceRows,
  getExpRows,
  getIncRows,
  getSavingsRows,
  openModal,
  openModals,
  removeRows,
} from './appController';
import { updateSettings } from './updateSettings';
import {
  updateBalanceItems,
  updateExpenses,
  updateIncome,
  createDataTemplate,
  updateSavings,
} from './updateFinData';
import {
  allFutureAssetChart,
  allFutureSavingsChart,
  allProjectionChart,
  expenseChart,
  futureSavingsCharts,
  incomeChart,
  incomeExpenseChart,
  individualFuturesCharts,
  netWorthChart,
  netWorthChartHomepage,
  // savingsChart,
  savingsGrowthChart,
  updateAllFutureAssetChart,
} from './apexChartsPopulation';
import { populatedData } from './populatedDataSets';
import { addHomepageCalcRow } from './homepageController';
import { sendMessage } from './admin';
import { acceptCookieConsent, getCookie } from './cookieNotice';
import { removeSpinner, renderSpinner } from './alerts';

// DOM ELEMENTS
// Account management
const loginForm = document.getElementById('home-form');
const forgotPassForm = document.getElementById('forgot-password-form');
const resetPassForm = document.getElementById('reset-password-form');
const signupForm = document.getElementById('signup-form');
const logOutBtn = document.querySelector('.logout-btn');
const btnsCreateDataTemplate = document.querySelectorAll(
  '.btn-create-data-template'
);
const userDataForm = document.getElementById('form-user-data');
const userPasswordForm = document.getElementById('form-user-password');
const btnVerifyAccount = document.getElementById('verify-account-btn');
const btnPaymentSuccess = document.getElementById('payment-success-btn');

// Contact
const contactForm = document.getElementById('contact-form');

// Charts
const expChart = document.querySelector('#exp-chart');
const incChart = document.querySelector('#inc-chart');
const savGroChart = document.querySelector('#savings-growth-chart');
const incExpChart = document.querySelector('#inc-exp-chart');
const netWorChart = document.querySelector('#net-worth-chart');
const allFuturesChart = document.querySelector('.all-futures-chart');
const futuresCharts = document.querySelectorAll('.futures-chart');
const allFuturesSavingChart = document.querySelector('.all-futures-sav-chart');
const futuresSavingCharts = document.querySelectorAll('.futures-sav-chart');
const updBalGraphs = document.getElementById('graph-years-bal');
const updSavGraphs = document.getElementById('graph-years-sav');
const allProjectionsChart = document.querySelector('#all-projections-chart');
const btnUpdateProjections = document.querySelector('#btn-generate-projection');

// Modals
const overlay = document.querySelector('.overlay');
const btnOpenModal = document.querySelector('.btn--open-modal');
const btnsOpenModal = document.querySelectorAll('.btn--open-modal');
const btnCloseModal = document.querySelector('.btn--close-modal');
const btnsCloseModal = document.querySelectorAll('.btn--close-modal');
const btnsAddIncExpRows = document.querySelectorAll('.btn--add-incexp-row');
const btnsAddBalanceRows = document.querySelectorAll('.btn--add-blnc-row');
let btnsRemoveRows = document.querySelectorAll('.btn__small--remove');
// const btnsRemoveRows = document.querySelectorAll('.btn__small--remove');
const btnSaveIncInput = document.querySelector('.btn-save-income');
const btnSaveExpInput = document.querySelector('.btn-save-expenses');
const btnSaveBalInput = document.querySelector('.btn-save-balances');
const btnSaveSavInput = document.querySelector('.btn-save-savings');

// Cookies
const btnCookieConsent = document.querySelector('.accept-cookies');

// Nav - for nav to work on smaller screens.
const btnsNav = document.querySelectorAll('.app-nav--btn');

if (btnsNav) {
  btnsNav.forEach((btn) =>
    btn.addEventListener('click', () => {
      document
        .querySelector('.app-nav')
        .classList.toggle('app-nav__toggle--show');
    })
  );
}

if (loginForm)
  loginForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;

    login(email, password);
  });

if (forgotPassForm) {
  forgotPassForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const email = document.getElementById('email').value;

    forgotPassword(email);
  });
}

if (resetPassForm) {
  resetPassForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;
    const token = window.location.href.split('/').pop();

    resetPassword(password, passwordConfirm, token);
  });
}

if (logOutBtn) logOutBtn.addEventListener('click', logout);

if (signupForm)
  signupForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;

    signup(name, email, password, passwordConfirm);
  });

if (btnVerifyAccount) {
  const url = window.location.pathname;
  const token = url.substring(url.lastIndexOf('/') + 1);
  verifyAccount(token);
}

if (btnPaymentSuccess) {
  renderSpinner();
  setTimeout(() => {
    removeSpinner();
    btnPaymentSuccess.classList.remove('hidden');
  }, 3000);
}

if (btnsCreateDataTemplate)
  [...btnsCreateDataTemplate].forEach((btn) =>
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const clicked = +e.target.closest('.modal__box').dataset.modal;
      createDataTemplate(populatedData(false, clicked), 'choose-profile');
    })
  );

if (userDataForm) {
  userDataForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;

    updateSettings({ name, email }, 'data');
  });
}

if (userPasswordForm) {
  userPasswordForm.addEventListener('submit', async (e) => {
    e.preventDefault();
    document.querySelector('.btn--save-password').textContent = 'Updating...';
    const passwordCurrent = document.getElementById('password-current').value;
    const password = document.getElementById('password').value;
    const passwordConfirm = document.getElementById('password-confirm').value;

    await updateSettings(
      { passwordCurrent, password, passwordConfirm },
      'password'
    );

    document.querySelector('.btn--save-password').textContent =
      'Save new password';
    document.getElementById('password-current').value = '';
    document.getElementById('password').value = '';
    document.getElementById('password-confirm').value = '';
  });
}

if (contactForm) {
  contactForm.addEventListener('submit', (e) => {
    e.preventDefault();
    const name = document.getElementById('name').value;
    const email = document.getElementById('email').value;
    const subject = document.getElementById('subject').value;
    const message = document.getElementById('message').value;

    document.querySelector('.home-form--btn').textContent = '...sending';

    sendMessage(name, email, subject, message);
  });
}

if (window.location.pathname === '/welcome') {
  btnsOpenModal.forEach((btn) => btn.addEventListener('click', openModals));
  btnsCloseModal.forEach((btn) => btn.addEventListener('click', closeModals));
  overlay.addEventListener('click', closeModals);
}

if (
  window.location.pathname === '/income' ||
  window.location.pathname === '/expenses' ||
  window.location.pathname === '/balances' ||
  window.location.pathname === '/savings'
) {
  if (btnCloseModal) btnCloseModal.addEventListener('click', closeModal);
  btnOpenModal.addEventListener('click', openModal);
  overlay.addEventListener('click', closeModal);
  btnsAddIncExpRows.forEach((btn) =>
    btn.addEventListener('click', (e) => {
      addIncExpRow(e);
    })
  );
  btnsAddBalanceRows.forEach((btn) =>
    btn.addEventListener('click', addBalanceRow)
  );

  btnsRemoveRows.forEach((btn) =>
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      removeRows(e);
    })
  );

  if (btnSaveIncInput) {
    btnSaveIncInput.addEventListener('click', (e) => {
      e.preventDefault();
      updateIncome(getIncRows());
    });
  }
  if (btnSaveExpInput) {
    btnSaveExpInput.addEventListener('click', (e) => {
      e.preventDefault();
      updateExpenses(getExpRows());
    });
  }

  if (btnSaveBalInput) {
    btnSaveBalInput.addEventListener('click', (e) => {
      e.preventDefault();
      updateBalanceItems(getBalanceRows());
    });
  }

  if (btnSaveSavInput) {
    btnSaveSavInput.addEventListener('click', (e) => {
      e.preventDefault();
      updateSavings(getSavingsRows());
      // if (checkSavingsPercent()) {
      //   updateSavings(getSavingsRows());
      // }
      // if (!checkSavingsPercent()) {
      //   return;
      // }
    });
  }
}

if (
  window.location.pathname === '/overview' ||
  window.location.pathname === '/income' ||
  window.location.pathname === '/expenses' ||
  window.location.pathname === '/balances' ||
  window.location.pathname === '/savings' ||
  window.location.pathname === '/projections'
) {
  // CHARTS

  if (netWorChart) {
    netWorthChart();
  }

  if (savGroChart) {
    savingsGrowthChart();
  }

  if (incExpChart) {
    incomeExpenseChart();
  }

  if (incChart) {
    incomeChart();
  }

  if (expChart) {
    expenseChart();
  }

  if (futuresCharts) {
    individualFuturesCharts(futuresCharts.length);
  }

  if (allFuturesChart) {
    allFutureAssetChart();
  }

  if (futuresSavingCharts) {
    futureSavingsCharts(futuresSavingCharts.length);
  }

  if (allFuturesSavingChart) {
    allFutureSavingsChart();
  }

  if (allProjectionsChart) {
    allProjectionChart();

    if (btnUpdateProjections) {
      btnUpdateProjections.addEventListener('click', () => {
        allProjectionsChart.firstChild.remove();
        allProjectionChart();
        document.querySelector('.content__box--graph').scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      });
    }
  }

  if (updBalGraphs) {
    updBalGraphs.addEventListener('change', () => {
      allFuturesChart.firstChild.remove();
      futuresCharts.forEach((chart) => chart.firstChild.remove());

      allFutureAssetChart();
      individualFuturesCharts(futuresCharts.length);
    });
  }

  if (updSavGraphs) {
    updSavGraphs.addEventListener('change', () => {
      allFuturesSavingChart.firstChild.remove();
      futuresSavingCharts.forEach((chart) => chart.firstChild.remove());

      allFutureSavingsChart();
      futureSavingsCharts(futuresSavingCharts.length);
    });
  }
}

//////////////////////////////////////////
// NET WORTH CALCULATOR - HOMEPAGE
const btnCalculate = document.querySelector('.btn--homepage-net-worth');
const btnAddHomepageAss = document.querySelector('.btn--homepage-add-ass');
const btnAddHomepageLia = document.querySelector('.btn--homepage-add-lia');

if (btnCalculate) {
  btnCalculate.addEventListener('click', () => {
    if (document.getElementById('try__container--joint')) {
      document.getElementById('try__container--joint').remove();
    }
    netWorthChartHomepage();
  });
}

if (btnAddHomepageAss) {
  btnAddHomepageAss.addEventListener('click', (e) => {
    addHomepageCalcRow(e, 'asset');
  });
}
if (btnAddHomepageLia) {
  btnAddHomepageLia.addEventListener('click', (e) => {
    addHomepageCalcRow(e, 'liability');
  });
}

// COOKIE POLICY
window.onload = () => {
  let cookie_consent = getCookie('user_cookie_consent');
  const cookieOverlay = document.querySelector('.cookie-overlay');
  if (cookie_consent != '') {
    cookieOverlay.classList.add('hidden');
  } else {
    cookieOverlay.classList.remove('hidden');
  }
};

if (btnCookieConsent) {
  btnCookieConsent.addEventListener('click', () => {
    acceptCookieConsent();
  });
}
